import styles from "@/components/Header/Nav/nav.module.scss";
import classnames from "classnames";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRouter } from "next/router";
import { logoutAuth0 } from "../../../helpers/environment";
import {
  Gravatar,
  SignInLinks,
  UserLinks,
} from "@/components/Header/Nav/nav-dropdown";
import { gsap } from "gsap";
import Button from "@/components/Button/button";
import AnimatedChevron from "@/components/Header/Nav/animated-chevron";
import { NavMenu } from "@/components/Header/Nav/nav";
import { Accordion } from "@ef-global/web-ui-react";
import { SettingsStoryblok } from "@/types/component-types-sb";
import { Session } from "next-auth";

const ToggleContext = createContext<{
  overlayNavActive: boolean;
  setOverlayNavActive(arg: boolean): void;
}>({
  overlayNavActive: false,
  setOverlayNavActive: () => {},
});

interface MobileUserNavProps {
  session: any;
  logoutUrl: string;
}

export const MobileUserNav = ({ session, logoutUrl }: MobileUserNavProps) => {
  return (
    <>
      <Gravatar src={session?.user?.image} />
      <div className={styles.mobileUserNavList}>
        <UserLinks logoutUrl={logoutUrl} tier={session?.user?.tier} />
      </div>
    </>
  );
};

interface OverlayNavProps {
  main_nav: SettingsStoryblok["main_nav"];
  ctaLink: SettingsStoryblok["main_nav_cta_link"];
  close: boolean;
  duration: number;
  session: Session | null;
}

export const OverlayNav = (props: OverlayNavProps) => {
  const { main_nav, close, duration, session, ctaLink } = props;
  const ref = useRef(null);
  const tlRef = useRef(gsap.timeline({ paused: true }));
  const [overlayNavActive, setOverlayNavActive] = useState(close);
  const toggleNav = () => setOverlayNavActive(!overlayNavActive);

  useEffect(() => {
    gsap.set(ref.current, {
      autoAlpha: 0,
      translateY: -24,
    });
    tlRef.current.to(ref.current, {
      autoAlpha: 1,
      translateY: 0,
      duration: duration / 1.5,
      ease: "power2.out",
    });
  }, [duration]);

  useEffect(() => {
    if (overlayNavActive && close) {
      setOverlayNavActive(false);
    }
    let tl: gsap.core.Timeline | null = null;
    if (tlRef.current) {
      tl = tlRef.current;
      overlayNavActive ? tl.restart() : tl.reverse();
    }
    return () => {
      tl?.kill();
    };
  }, [overlayNavActive, close]);

  return (
    <ToggleContext.Provider value={{ overlayNavActive, setOverlayNavActive }}>
      <div className={styles.proCyclingNav__buttons}>
        {ctaLink?.cachedUrl ? (
          <Button
            link={`/${ctaLink.cachedUrl}`}
            size={"small"}
            className={styles.ctaButton}
          >
            Pricing
          </Button>
        ) : null}
        <button
          className={styles.proCyclingNav__toggle}
          aria-label={`Toggle navigation`}
          onClick={() => toggleNav()}
        >
          {" "}
          <AnimatedChevron active={overlayNavActive} duration={duration} />
        </button>
      </div>
      <nav
        className={classnames(
          styles.proCyclingNavOverlay,
          overlayNavActive ? styles.active : ""
        )}
      >
        <div className={styles.proCyclingNav__panelWrap}>
          <div
            className={`${styles.proCyclingNav__panel} layout-grid ${
              overlayNavActive ? styles.active : ""
            }`}
            ref={ref}
          >
            <NavMenuOverlay
              main_nav={main_nav}
              duration={duration}
              active={overlayNavActive}
              session={session}
            />
          </div>
        </div>
      </nav>
    </ToggleContext.Provider>
  );
};

interface NavMenuOverlayProps {
  main_nav: SettingsStoryblok["main_nav"];
  active: boolean;
  duration: number;
  session: Session | null;
}

export const NavMenuOverlay = ({
  main_nav,
  active,
  duration,
  session,
}: NavMenuOverlayProps) => {
  const { setOverlayNavActive } = useContext(ToggleContext);
  const router = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    const handleRouteChange = () => setOverlayNavActive(false);
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  return (
    <>
      {session ? (
        <div className={styles.mobileUserNav}>
          <MobileUserNav logoutUrl={logoutAuth0} session={session} />
        </div>
      ) : (
        <a
          href="https://app.teamefcoaching.com"
          className={styles.mobileUserNav}
        >
          Sign in
        </a>
      )}
      <NavMenu
        main_nav={main_nav}
        overlay
        animate
        duration={duration}
        active={active}
        ref={ref}
      />
    </>
  );
};
