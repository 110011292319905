import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styles from "./animated-chevron.module.scss";
import classnames from "classnames";

interface Props {
  active: boolean;
  duration: number;
  className?: string;
}

const AnimatedChevron = ({ active, duration, className }: Props) => {
  const ref = useRef(null);
  const q = gsap.utils.selector(ref);

  useEffect(() => {
    gsap.set(q(".chevron__left"), {
      transformOrigin: "100% 50%",
    });
    gsap.set(q(".chevron__right"), {
      transformOrigin: "0% 50%",
    });
  }, [q]);

  useEffect(() => {
    const animateOpen = () => {
      gsap.to(q(".chevron__left"), {
        rotation: -43,
        y: -6,
        duration: duration / 2,
        ease: "power2.easeOut",
      });
      gsap.to(q(".chevron__right"), {
        rotation: 43,
        y: -6,
        duration: duration / 2,
        ease: "power2.easeOut",
      });
    };
    const animateClose = () => {
      gsap.to(q(".chevron__left"), {
        rotation: 43,
        y: 12,
        duration: duration / 2,
        ease: "power2.easeOut",
      });
      gsap.to(q(".chevron__right"), {
        rotation: -43,
        y: 12,
        duration: duration / 2,
        ease: "power2.easeOut",
      });
    };

    active ? animateOpen() : animateClose();
  }, [active, duration]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = classnames(styles.animatedChevron, className);

  return (
    <svg
      ref={ref}
      className={classes}
      version="1.1"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <g className="chevron__left">
        <line
          className="chevron__line1"
          x1="5"
          y1="20"
          x2="20"
          y2="20"
          strokeLinecap="butt"
        />
        <line
          className="chevron__line1"
          x1="20"
          y1="20"
          x2="25"
          y2="20"
          strokeLinecap="round"
        />
      </g>
      <g className="chevron__right">
        <line
          className="chevron__line2"
          x1="30"
          y1="20"
          x2="45"
          y2="20"
          strokeLinecap="butt"
        />
        <line
          className="chevron__line2"
          x1="25"
          y1="20"
          x2="30"
          y2="20"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default AnimatedChevron;
