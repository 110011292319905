import React, { useRef, useEffect, useState } from "react";
import classnames from "classnames";

import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import Image from "next/image";
import styles from "@/components/Header/Nav/nav.module.scss";
import { basicLoader } from "../../../helpers/utils";
import { useRouter } from "next/router";
import Link from "@/components/Link";
import { gsap } from "gsap";
import { signIn } from "next-auth/react";
import Button, { ButtonProps } from "@/components/Button/button";
import { Accordion } from "@ef-global/web-ui-react";
import { NavDropdownStoryblok } from "@/types/component-types-sb";

interface DrawerProps {
  children: React.ReactNode;
  className?: string;
}

const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <div
      className={classnames(styles.dropdownPanel, className)}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  );
});

interface NavDropdownProps {
  buttonChildren: React.ReactNode;
  children: React.ReactNode;
  buttonSize?: ButtonProps["size"];
  buttonStyle?: ButtonProps["btnStyle"];
  position?: NavDropdownStoryblok["position"];
  hover?: boolean;
  className?: string;
  gravatar?: string;
}

export const NavDropdown = ({
  buttonChildren,
  children,
  buttonSize,
  buttonStyle,
  position,
  hover,
  className,
  ...rest
}: NavDropdownProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const duration = 0.2;

  useEffect(() => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  }, [router.asPath]);

  useOnClickOutside(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    isOpen
      ? gsap.fromTo(
          ref.current,
          { autoAlpha: 0, y: 0 },
          { autoAlpha: 1, y: 8, duration }
        )
      : gsap.fromTo(
          ref.current,
          { autoAlpha: 1, y: 8 },
          { autoAlpha: 0, y: 0, duration }
        );
  }, [isOpen]);

  return (
    <div
      className={styles.navDropdown}
      ref={wrapperRef}
      onMouseEnter={() => hover && setIsOpen(true)}
      onMouseLeave={() => hover && setIsOpen(false)}
    >
      <Button
        className={classnames(styles.dropdownButton, className)}
        onClick={() => !isOpen && !hover && setIsOpen(true)}
        size={buttonSize}
        btnStyle={buttonStyle}
        {...rest}
      >
        {buttonChildren}
      </Button>
      {mounted ? (
        <Drawer
          ref={ref}
          className={classnames(
            styles[position || ""],
            mounted ? styles.mounted : ""
          )}
        >
          {children}
        </Drawer>
      ) : null}
    </div>
  );
};

interface NavDropdownCollapsibleProps {
  buttonChildren: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const NavDropdownCollapsible = ({
  buttonChildren,
  children,
  className,
}: NavDropdownCollapsibleProps) => {
  return (
    <li className={classnames(styles.efNav__item, styles.navItemDropdown)}>
      <Accordion className={styles.navDropdownCollapsible}>
        <Accordion.Item>
          <Accordion.ItemTitle
            icon
            as={"button"}
            className={classnames(styles.navCollapsibleTrigger, className)}
          >
            {buttonChildren}
          </Accordion.ItemTitle>
          <Accordion.ItemContent className={styles.navCollapsibleContent}>
            {children}
          </Accordion.ItemContent>
        </Accordion.Item>
      </Accordion>
    </li>
  );
};

interface SignInLinksProps {
  linkClass?: string;
}
export const SignInLinks = ({ linkClass }: SignInLinksProps) => {
  return (
    <>
      <button
        className={classnames(styles.panelLink, linkClass)}
        onClick={() =>
          signIn("auth0", {
            callbackUrl: "/sign-in",
          })
        }
      >
        Foundations members
      </button>
      <Link
        href={`https://app.teamefcoaching.com`}
        className={classnames(styles.panelLink, linkClass)}
        target={"_blank"}
      >
        1:1 coaching members
      </Link>
    </>
  );
};

export const SignInButton = () => {
  return (
    <Button
      className="u-ml-xs"
      size="small"
      btnStyle="border"
      link={`https://app.teamefcoaching.com`}
    >
      Sign in
    </Button>
  );
};

const FoundationsLinks = () => (
  <>
    <Link className={styles.panelLink} href={`/my-coaching`}>
      My coaching
    </Link>
    <Link className={styles.panelLink} href={`/my-coaching/knowledge-base`}>
      Knowledge base
    </Link>
    <Link className={styles.panelLink} href={`/my-coaching/content-hub`}>
      Content hub
    </Link>
    <Link className={styles.panelLink} href={`/my-coaching/account`}>
      Account
    </Link>
    <Link className={styles.panelLink} href={`/my-coaching/training-plans`}>
      Plans
    </Link>
  </>
);

const BasicLinks = () => (
  <>
    <Link className={styles.panelLink} href={`/training-plans`}>
      Training plans
    </Link>
  </>
);

export const UserLinks = ({
  logoutUrl,
  tier,
}: {
  logoutUrl: string;
  tier: string;
}) => {
  return (
    <>
      {{
        BASIC: <BasicLinks />,
        FOUNDATIONS: <FoundationsLinks />,
      }[tier] || null}
      <Link
        className={classnames(styles.panelLink, "u-mt-xs")}
        href={logoutUrl}
      >
        Sign out
      </Link>
    </>
  );
};

interface GravatarProps {
  src: string;
}

export const Gravatar = (props: GravatarProps) => {
  const { src } = props;

  if (!src) {
    return <div className={styles.noGravatar} />;
  }

  return (
    <Image
      className={styles.navGravatar}
      width={32}
      height={32}
      layout="intrinsic"
      loader={basicLoader}
      src={src}
      alt={"User gravatar"}
    />
  );
};
