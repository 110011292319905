import React, {
  createContext,
  createElement,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Link from "@/components/Link";
import { SettingsContext } from "@/context/SettingsContext";
import Measure from "react-measure";
import { NextRouter, useRouter } from "next/router";
import { gsap } from "gsap";
import styles from "./nav.module.scss";
import Button from "@/components/Button/button";
import { useSession } from "next-auth/react";
import { logoutAuth0 } from "../../../helpers/environment";
import {
  Gravatar,
  SignInButton,
  UserLinks,
  NavDropdown,
  NavDropdownCollapsible,
} from "@/components/Header/Nav/nav-dropdown";
import { OverlayNav } from "@/components/Header/Nav/mobile";
import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import useSWR from "swr";
import classnames from "classnames";
import {
  NavDropdownStoryblok,
  NavItemStoryblok,
  SettingsStoryblok,
} from "@/types/component-types-sb";
import { Session } from "next-auth";
import { Tier } from "@prisma/client";

const defaultState = {
  widthWrapper: 0,
  widthNav: 0,
};

const NavContext = createContext({
  widthData: defaultState,
  setWidthData: (arg: typeof defaultState) => {},
});

interface NavProps {
  close: boolean;
  duration: number;
  className?: string;
}

const Nav = ({ close, duration }: NavProps) => {
  const { settings } = useContext(SettingsContext);
  const { main_nav, main_nav_cta_link } = settings?.content || {};
  const { data: session } = useSession();
  const { data: tier } = useSWR(`/api/user/get-tier/`);
  const [widthData, setWidthData] = useState(defaultState);

  // Show overlay nav if there isn't enough space for the nav items
  const [showOverlayNav, setShowOverlayNav] = useState(false);

  // magic number for pricing button
  useEffect(() => {
    setShowOverlayNav(widthData.widthNav + 224 > widthData.widthWrapper);
  }, [widthData]);

  return (
    <NavContext.Provider value={{ widthData, setWidthData }}>
      <MeasureComponent />
      {showOverlayNav ? (
        <OverlayNav
          main_nav={main_nav}
          ctaLink={main_nav_cta_link}
          close={close}
          duration={duration}
          session={session}
        />
      ) : (
        <InlineNav
          main_nav={main_nav}
          ctaLink={main_nav_cta_link}
          session={session}
          tier={tier}
        />
      )}
    </NavContext.Provider>
  );
};

const NavItem = (props: NavItemStoryblok) => {
  const router = useRouter();
  return (
    <li
      className={classnames(
        styles.efNav__item,
        isActive(props, router) ? styles.active : "",
        props.className
      )}
      key={props._uid}
    >
      <Link
        className={styles.efNav__link}
        target={props?.link?.linktype === "url" ? "_blank" : undefined}
        rel={
          props?.link?.linktype === "url" ? "noopener noreferrer" : undefined
        }
        href={getUrl(props)}
      >
        {props.name}
      </Link>
    </li>
  );
};

const NavItemDropdown = (props: NavDropdownStoryblok) => {
  const { nav_items, position, name } = props;
  const router = useRouter();
  const isActive = childIsActive(nav_items || [], router);

  return (
    <NavDropdown
      buttonStyle="blackLink"
      buttonChildren={name}
      className={classnames(
        styles.navDropdownButton,
        isActive ? styles.active : ""
      )}
      position={position}
      hover
    >
      <ul className={styles.navDropdownList}>
        {nav_items?.map((item) => (
          <NavItem
            key={`${item._uid}-1`}
            {...item}
            className={styles.panelLink}
          />
        ))}
      </ul>
    </NavDropdown>
  );
};

const NavItemCollapsible = (props: NavDropdownStoryblok) => {
  const { nav_items, name } = props;
  const router = useRouter();
  const isActive = childIsActive(nav_items || [], router);

  return (
    <NavDropdownCollapsible
      buttonChildren={name}
      className={isActive ? styles.active : ""}
    >
      <ul className={styles.navDropdownList}>
        {nav_items?.map((item) => (
          <NavItem
            key={`${item._uid}-1`}
            {...item}
            className={styles.panelLink}
          />
        ))}
      </ul>
    </NavDropdownCollapsible>
  );
};

const NavComponents = (overlay: boolean, key: string) => {
  if (key === "nav-item") return NavItem;
  if (key === "nav-dropdown")
    return overlay ? NavItemCollapsible : NavItemDropdown;
  return null;
};

export const NavMenu = forwardRef<
  HTMLUListElement,
  {
    main_nav: SettingsStoryblok["main_nav"];
    overlay?: boolean;
    animate?: boolean;
    duration?: number;
    active?: boolean;
  }
>(({ main_nav, active, duration, animate, overlay }, ref) => {
  const tlRef = useRef(gsap.timeline({ paused: true }));
  const q = gsap.utils.selector(ref);
  useEffect(() => {
    if (!animate) return;
    const items = q(`.${styles.efNav__item}`);
    gsap.set(items, {
      autoAlpha: 0,
      translateY: -24,
    });
    tlRef.current.to(items, {
      autoAlpha: 1,
      translateY: 0,
      duration: duration ? duration / 3 : undefined,
      stagger: 0.05,
      ease: "power2.out",
    });
  }, [animate, duration]);

  useEffect(() => {
    let tl: gsap.core.Timeline | null = null;
    if (tlRef.current) {
      tl = tlRef.current;
      active ? tl.restart() : tl.reverse();
    }
    return () => {
      tl && tl.kill();
    };
  }, [active]);

  return (
    <ul className={styles.proCyclingNav__menu} ref={ref}>
      {main_nav &&
        main_nav.map((navItem) =>
          createElement(NavComponents(!!overlay, navItem.component) as any, {
            expanded: active,
            ...navItem,
          })
        )}
    </ul>
  );
});

interface InlineNavProps {
  main_nav: SettingsStoryblok["main_nav"];
  ctaLink: SettingsStoryblok["main_nav_cta_link"];
  session: Session | null;
  tier: Tier;
}

const InlineNav = (props: InlineNavProps) => {
  const { main_nav, ctaLink, session, tier } = props;
  const ButtonContent = () => (
    <>
      <IconChevronDown className={styles.dropdownChevron} />
      <Gravatar src={session?.user?.image || ""} />
    </>
  );
  return (
    <nav className={styles.proCyclingNavInline}>
      <NavMenuInline main_nav={main_nav} />
      {ctaLink?.cachedUrl ? (
        <Button
          link={`/${ctaLink?.cachedUrl}`}
          size={"small"}
          className={styles.ctaButton}
        >
          Pricing
        </Button>
      ) : null}
      {session ? (
        <NavDropdown
          gravatar={session.user.image || undefined}
          buttonChildren={<ButtonContent />}
          buttonSize={"small"}
          buttonStyle={"none"}
        >
          <UserLinks logoutUrl={logoutAuth0} tier={tier} />
        </NavDropdown>
      ) : (
        <>
          <SignInButton />
        </>
      )}
    </nav>
  );
};

const NavMenuInline = ({
  main_nav,
}: {
  main_nav: SettingsStoryblok["main_nav"];
}) => {
  const { widthData, setWidthData } = useContext(NavContext);
  const [prevWidth, setPrevWidth] = useState(0);
  const updateWidthData = (width: number) => {
    if (width !== prevWidth) {
      setWidthData({ ...widthData, widthNav: width });
      setPrevWidth(width);
    }
  };
  return (
    <Measure
      bounds
      onResize={(contentRect) =>
        contentRect.bounds && updateWidthData(contentRect.bounds.width)
      }
    >
      {({ measureRef }) => <NavMenu main_nav={main_nav} ref={measureRef} />}
    </Measure>
  );
};

// this component measures the available space for the nav
const MeasureComponent = () => {
  const { widthData, setWidthData } = useContext(NavContext);
  return (
    <Measure
      bounds
      onResize={(contentRect) =>
        contentRect.bounds &&
        setWidthData({ ...widthData, widthWrapper: contentRect.bounds.width })
      }
    >
      {({ measureRef }) => (
        <div ref={measureRef} className={styles.measureComponent} />
      )}
    </Measure>
  );
};

interface LinkUrlCached {
  link?: {
    url?: string;
    cached_url?: string;
  };
}

const getUrl = (linkItem: LinkUrlCached) =>
  linkItem?.link?.url || `/${linkItem?.link?.cached_url}`;

const isActive = (navItem: LinkUrlCached, router: NextRouter) => {
  const item = getUrl(navItem);
  const section = item.startsWith("/") ? item.split("/")[1] : "";
  const path = router.asPath.startsWith("/") ? router.asPath.split("/")[1] : "";
  return section && section === path;
};

const childIsActive = (items: LinkUrlCached[], router: NextRouter) =>
  items.map((item) => isActive(item, router)).includes(true);

export default Nav;
